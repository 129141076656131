import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./css/normalize.css";
import "./css/webflow.css";
import "./css/bugbusterslabs.webflow.css";
import "./css/frontHeader.css";
import pentest from "./images/PenTest.png";
import bugbounty from "./images/BugBounty_2.png";
import option4 from "./images/option4.png";
import externalAtt from "./images/External-Attack.png";
import redteam from "./images/RedTeaming_1.png";
import darkweb from "./images/Darkweb_1.png";

export default function Header() {
  const navigate = useNavigate();
  const [isMobileNav, setIsMobileNav] = useState(false);
  const IsbugBusterUrl = window.location.href.includes("bugbuster");
  return (
    <>
      <div className="uui-navbar07_container">
        <nav role="navigation" className="w-nav-menu2">
          <div className="uui-navbar07_menu-right">
            <NavLink to="/contactus" className="uui-navbar07_link w-nav-link">
              Contact Us
            </NavLink>
            {IsbugBusterUrl ? (
              <>
                <a
                  href="https://www.ai-sentinelvdp.com/customer/login"
                  className="uui-navbar07_link w-nav-link"
                >
                  Customer Login
                </a>
                <a
                  href="https://www.ai-sentinelvdp.com/researcher/login"
                  className="uui-navbar07_link w-nav-link"
                >
                  Researcher Login
                </a>
              </>
            ) : (
              <>
                <NavLink
                  to="/customer/login"
                  className="uui-navbar07_link w-nav-link"
                >
                  Customer Login
                </NavLink>
                <NavLink
                  to="/researcher/login"
                  className="uui-navbar07_link w-nav-link"
                >
                  Researcher Login
                </NavLink>
              </>
            )}
          </div>
        </nav>
      </div>
      <div
        // data-w-id="e7007065-83bb-da53-6f19-3e844b29a5d4"
        // data-animation="default"
        // data-collapse="medium"
        // data-duration="400"
        // data-easing="ease"
        // data-easing2="ease"
        role="banner"
        className="uui-navbar07_component w-nav"
        style={{ height: "50px" }}
      >
        <div className="uui-navbar07_container">
          <Link
            to="/"
            aria-current="page"
            className="w-inline-block w--current"
          >
            <img src={option4} loading="lazy" width="50" alt="" />
          </Link>
          <Link
            to="/"
            aria-current="page"
            className="link-block w-inline-block w--current"
          >
            <div className="text-block hyperesh">Bugbusterslabs</div>
          </Link>
          <nav
            role="navigation"
            className="uui-navbar07_menu w-nav-menu"
            style={{ height: "100%" }}
          >
            <div
              className="uui-navbar07_menu-left"
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <NavLink
                to="/"
                aria-current="page"
                className="uui-navbar07_link w-nav-link "
              >
                Home
              </NavLink>
              <div
                className="  productOption d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <div>
                  <p
                    className="uui-navbar07_link pb-0"
                    style={{ paddingRight: "4px", paddingTop: "17px" }}
                  >
                    Products
                  </p>
                </div>
                <i className="fa-solid fa-angle-down"></i>

                <div className="productsElements">
                  <Link to="/bug-bounty" className="d-flex productElementItem">
                    <div className="col-3 d-flex justify-content-center">
                      <div className="productElementImg">
                        <img src={bugbounty} alt="" />
                      </div>
                    </div>
                    <div className="col-8">
                      <p className="productElementP">Bug Bounty Platform</p>
                      <span className="productElementSpan">
                        Fostering responsible collaboration for enhanced
                        security.
                      </span>
                    </div>
                  </Link>
                  <Link to="/pentesting" className="d-flex productElementItem">
                    <div className="col-3 d-flex justify-content-center">
                      <div className="productElementImg">
                        <img src={pentest} alt="" />
                      </div>
                    </div>
                    <div className="col-8">
                      <p className="productElementP">Penetration Testing</p>
                      <span className="productElementSpan">
                        Simulating cyberattacks to discover and address
                        vulnerabilities.
                      </span>
                    </div>
                  </Link>
                  <Link
                    to="/externalattacksurface"
                    className="d-flex productElementItem"
                  >
                    <div className="col-3 d-flex justify-content-center">
                      <div className="productElementImg">
                        <img src={externalAtt} alt="" />
                      </div>
                    </div>
                    <div className="col-8">
                      <p className="productElementP">External Attack Surface</p>
                      <span className="productElementSpan">
                        Identifying and reducing vulnerabilities for enhanced
                        security.
                      </span>
                    </div>
                  </Link>
                  <Link to="/redteaming" className="d-flex productElementItem">
                    <div className="col-3 d-flex justify-content-center">
                      <div className="productElementImg">
                        <img src={redteam} alt="" />
                      </div>
                    </div>
                    <div className="col-8">
                      <p className="productElementP">Red Teaming</p>
                      <span className="productElementSpan">
                        Simulating real-world cyberattacks to test an
                        organization's security posture
                      </span>
                    </div>
                  </Link>
                  <Link
                    to="/darkwebmonitoring"
                    className="d-flex productElementItem"
                  >
                    <div className="col-3 d-flex justify-content-center">
                      <div className="productElementImg">
                        <img src={darkweb} alt="" />
                      </div>
                    </div>
                    <div className="col-8">
                      <p className="productElementP">Dark Web Monitoring</p>
                      <span className="productElementSpan">
                        Scan, analyze, and track the hidden corners of the
                        internet
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
              <NavLink to="/programs" className="uui-navbar07_link w-nav-link">
                Programs
              </NavLink>
              <NavLink to="/partner" className="uui-navbar07_link w-nav-link">
                Partner
              </NavLink>

              <div
                className="  productOption d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <div>
                  <NavLink
                    to="/resources"
                    className="uui-navbar07_link pb-0"
                    style={{ paddingRight: "4px", paddingTop: "17px" }}
                  >
                    Resources
                  </NavLink>
                </div>
                <i className="fa-solid fa-angle-down"></i>

                <div
                  className="productsElements"
                  id="companydropdown"
                  style={{ top: "27px" }}
                >
                  <ul className="text-white list-unstyled">
                    <li
                      onClick={() => navigate("/resources/docs/customer")}
                      className=" productElementItem d-flex align-items-center"
                    >
                      <Link to="/resources/docs/customer">Customer Docs</Link>
                    </li>
                    <li
                      className=" productElementItem"
                      onClick={() => navigate("/resources/docs/researcher")}
                    >
                      <Link to="/resources/docs/researcher">
                        Researcher Docs
                      </Link>
                    </li>
                    <li
                      className=" productElementItem"
                      onClick={() => navigate("/resources/docs/apis")}
                    >
                      <Link to="/resources/docs/apis">API Docs</Link>
                    </li>
                    <li className=" productElementItem">
                      <a href="https://blog.bugbusterslabs.com/">Blog</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="  productOption d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <div>
                  <p
                    className="uui-navbar07_link pb-0"
                    style={{ paddingRight: "4px", paddingTop: "17px" }}
                  >
                    Company
                  </p>
                </div>
                <i className="fa-solid fa-angle-down"></i>

                <div className="productsElements" id="companydropdown">
                  <ul className="text-white list-unstyled">
                    <li
                      onClick={() => navigate("/aboutus")}
                      className="productElementItem"
                    >
                      <Link to="/aboutus">About Us</Link>
                    </li>
                    <li
                      onClick={() => navigate("/privacypolicy")}
                      className="productElementItem"
                    >
                      <Link to="/privacypolicy">Privacy Policy</Link>
                    </li>
                    <li
                      onClick={() => navigate("/terms&condition")}
                      className="productElementItem"
                    >
                      <Link to="/terms&condition">Terms & Conditions</Link>
                    </li>
                    <li
                      onClick={() => navigate("/cancle&refund")}
                      className="productElementItem"
                    >
                      <Link to="/cancle&refund">
                        Cancellation & Refund Policy
                      </Link>
                    </li>
                    <li
                      onClick={() => navigate("/securitypolicy")}
                      className="productElementItem"
                    >
                      <Link to="/securitypolicy">Security Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="  productOption d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <div>
                  <p
                    className="uui-navbar07_link pb-0"
                    style={{ paddingRight: "4px", paddingTop: "17px" }}
                  >
                    Researcher
                  </p>
                </div>
                <i className="fa-solid fa-angle-down"></i>

                <div className="productsElements">
                  <ul className="list-unstyled">
                    <li
                      onClick={() => navigate("/leaderBoard")}
                      className="productElementItem p-3"
                    >
                      Leaderboard
                    </li>
                  </ul>
                </div>
              </div>
              <NavLink to="/faq" className="uui-navbar07_link w-nav-link">
                FAQ
              </NavLink>
            </div>
            <div className="uui-navbar07_menu-right">
              <div className="uui-navbar07_button-wrapper">
                <Link
                  to="/try-bugbounty"
                  className="uui-button w-inline-block text-white"
                >
                  <div>Try BugBounty</div>
                </Link>
              </div>
            </div>
          </nav>

          {isMobileNav && (
            <nav
              role="navigation"
              className="uui-navbar07_menu responsiveNav"
            // style={{ height: "100%" }}
            >
              <div
                className="uui-navbar07_menu-left"
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <NavLink
                  to="/"
                  aria-current="page"
                  className="uui-navbar07_link w-nav-link "
                >
                  Home
                </NavLink>
                <div
                  className="  productOption d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <p
                      className="uui-navbar07_link pb-0"
                      style={{ paddingRight: "4px", paddingTop: "17px" }}
                    >
                      Products
                    </p>
                  </div>
                  <i className="fa-solid fa-angle-down"></i>

                  <div className="productsElements">
                    <Link
                      to="/bug-bounty"
                      className="d-flex productElementItem"
                    >
                      <div className="col-3 d-flex justify-content-center">
                        <div className="productElementImg">
                          <img src={bugbounty} alt="" />
                        </div>
                      </div>
                      <div className="col-8">
                        <p className="productElementP">Bug Bounty Platform</p>
                        <span className="productElementSpan">
                          Fostering responsible collaboration for enhanced
                          security.
                        </span>
                      </div>
                    </Link>
                    <Link
                      to="/pentesting"
                      className="d-flex productElementItem"
                    >
                      <div className="col-3 d-flex justify-content-center">
                        <div className="productElementImg">
                          <img src={pentest} alt="" />
                        </div>
                      </div>
                      <div className="col-8">
                        <p className="productElementP">Penetration Testing</p>
                        <span className="productElementSpan">
                          Simulating cyberattacks to discover and address
                          vulnerabilities.
                        </span>
                      </div>
                    </Link>
                    <Link
                      to="/externalattacksurface"
                      className="d-flex productElementItem"
                    >
                      <div className="col-3 d-flex justify-content-center">
                        <div className="productElementImg">
                          <img src={externalAtt} alt="" />
                        </div>
                      </div>
                      <div className="col-8">
                        <p className="productElementP">
                          External Attack Surface
                        </p>
                        <span className="productElementSpan">
                          Identifying and reducing vulnerabilities for enhanced
                          security.
                        </span>
                      </div>
                    </Link>
                    <Link
                      to="/redteaming"
                      className="d-flex productElementItem"
                    >
                      <div className="col-3 d-flex justify-content-center">
                        <div className="productElementImg">
                          <img src={redteam} alt="" />
                        </div>
                      </div>
                      <div className="col-8">
                        <p className="productElementP">Red Teaming</p>
                        <span className="productElementSpan">
                          Simulating real-world cyberattacks to test an
                          organization's security posture
                        </span>
                      </div>
                    </Link>
                    <Link
                      to="/darkwebmonitoring"
                      className="d-flex productElementItem"
                    >
                      <div className="col-3 d-flex justify-content-center">
                        <div className="productElementImg">
                          <img src={darkweb} alt="" />
                        </div>
                      </div>
                      <div className="col-8">
                        <p className="productElementP">Dark Web Monitoring</p>
                        <span className="productElementSpan">
                          Scan, analyze, and track the hidden corners of the
                          internet
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
                <NavLink
                  to="/programs"
                  className="uui-navbar07_link w-nav-link"
                >
                  Programs
                </NavLink>
                <NavLink to="/partner" className="uui-navbar07_link w-nav-link">
                  Partner
                </NavLink>

                <div
                  className="  productOption d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <NavLink
                      to="/resources"
                      className="uui-navbar07_link pb-0"
                      style={{ paddingRight: "4px", paddingTop: "17px" }}
                    >
                      Resources
                    </NavLink>
                  </div>
                  <i className="fa-solid fa-angle-down"></i>

                  <div
                    className="productsElements"
                    id="companydropdown"
                    style={{ top: "27px" }}
                  >
                    <ul className="text-white list-unstyled">
                      <li
                        onClick={() => navigate("/resources/docs/customer")}
                        className=" productElementItem d-flex align-items-center"
                      >
                        <Link to="/resources/docs/customer">Customer Docs</Link>
                      </li>
                      <li
                        className=" productElementItem"
                        onClick={() => navigate("/resources/docs/researcher")}
                      >
                        <Link to="/resources/docs/researcher">
                          Researcher Docs
                        </Link>
                      </li>
                      <li
                        className=" productElementItem"
                        onClick={() => navigate("/resources/docs/apis")}
                      >
                        <Link to="/resources/docs/apis">API Docs</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className="  productOption d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <p
                      className="uui-navbar07_link pb-0"
                      style={{ paddingRight: "4px", paddingTop: "17px" }}
                    >
                      Company
                    </p>
                  </div>
                  <i className="fa-solid fa-angle-down"></i>

                  <div className="productsElements" id="companydropdown">
                    <ul className="text-white list-unstyled">
                      <li
                        onClick={() => navigate("/aboutus")}
                        className="productElementItem"
                      >
                        <Link to="/aboutus">About Us</Link>
                      </li>
                      <li
                        onClick={() => navigate("/privacypolicy")}
                        className="productElementItem"
                      >
                        <Link to="/privacypolicy">Privacy Policy</Link>
                      </li>
                      <li
                        onClick={() => navigate("/terms&condition")}
                        className="productElementItem"
                      >
                        <Link to="/terms&condition">Terms & Conditions</Link>
                      </li>
                      <li
                        onClick={() => navigate("/cancle&refund")}
                        className="productElementItem"
                      >
                        <Link to="/cancle&refund">
                          Cancellation & Refund Policy
                        </Link>
                      </li>
                      <li
                        onClick={() => navigate("/securitypolicy")}
                        className="productElementItem"
                      >
                        <Link to="/securitypolicy">Security Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <NavLink to="/faq" className="uui-navbar07_link w-nav-link">
                  FAQ
                </NavLink>
              </div>
              <div className="uui-navbar07_menu-right">
                <div className="uui-navbar07_button-wrapper">
                  <Link
                    to="/try-bugbounty"
                    className="uui-button w-inline-block text-white"
                  >
                    <div>Try BugBounty</div>
                  </Link>
                </div>
              </div>
            </nav>
          )}

          <div onClick={() => setIsMobileNav(!isMobileNav)} className="navMenu">
            <i className="fa-solid fa-bars"></i>
          </div>
        </div>
      </div>
    </>
  );
}
